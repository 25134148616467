module.exports = [{
      plugin: require('../node_modules/@outdoormap/gatsby-plugin-brand-sites/gatsby-browser.js'),
      options: {"plugins":[],"apiUrl":"https://api.naturkartan.se","apiKey":"74439e0c-abab-419e-9227-27b47fae0811","guideId":"304","siteUrl":"https://en.trailsofvastmanland.se","lang":"en","typesCategories":{"mountainbiking":["16","31"],"hiking":["33"],"paddling":["37"],"outdoors":["30"]},"typesCategoriesIncluded":{"mountainbiking":["16","31"],"hiking":["33"],"paddling":["37"],"outdoors":["30"]},"imagePlaceholderColor":"#bb8b6f","templates":{"type":"/build/e797193a-420f-4301-ad5e-77418ce55853/src/templates/type.js","site":"/build/e797193a-420f-4301-ad5e-77418ce55853/src/templates/site.js","page":"/build/e797193a-420f-4301-ad5e-77418ce55853/src/templates/page.js","articles":"/build/e797193a-420f-4301-ad5e-77418ce55853/src/templates/articles.js","articlesPost":"/build/e797193a-420f-4301-ad5e-77418ce55853/src/templates/articles-post.js","news":"/build/e797193a-420f-4301-ad5e-77418ce55853/src/templates/news.js","newsPost":"/build/e797193a-420f-4301-ad5e-77418ce55853/src/templates/news-post.js","events":"/build/e797193a-420f-4301-ad5e-77418ce55853/src/templates/events.js","eventsPost":"/build/e797193a-420f-4301-ad5e-77418ce55853/src/templates/events-post.js","search":"/build/e797193a-420f-4301-ad5e-77418ce55853/src/templates/search.js"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"lang":"en","name":"Trails of Västmanland","short_name":"Trails of Västmanland","description":"Utvalda leder för dig som vill uppleva Västmanland","theme_color":"#005096","background_color":"#fff","icon":"static/meta-images/icon.png","start_url":"/","display":"standalone","cache_busting_mode":"none","include_favicon":false,"legacy":false,"theme_color_in_head":true,"crossOrigin":"anonymous","cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
